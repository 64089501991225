import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Paper,
  Container,
  Typography,
  Button,
  TextField,
  Box,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
} from '@mui/material';
import PropTypes from 'prop-types';

import { isCandor } from '../../../../utils/roles';
import { AuthContext } from '../../../../auth-context';
import { getFormsButtonText } from '../../../../utils/button-messages';
import { extractNumericCharacters } from '../../../../utils/constants';
import { MEASUREMENT_CONSTRAINTS, MEASUREMENT_ERROR } from '../../../../utils/isValidMeasurement';

const PREFIX = 'BasicFormElementPresentation';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  accountGridContainer: `${PREFIX}-accountGridContainer`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  inputContainer: `${PREFIX}-inputContainer`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  chip: `${PREFIX}-chip`,
  candorPaper: `${PREFIX}-candorPaper`,
  candorHeading: `${PREFIX}-candorHeading`,
  candorSubheading: `${PREFIX}-candorSubheading`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
    direction: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.accountGridContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    margin: '40px auto',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '40px',
    width: '100%',
    height: '100%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.inputContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
  },

  [`& .${classes.button}`]: {
    margin: '20px auto 10px auto',
    width: '140px',
    display: 'block',
  },

  [`& .${classes.chip}`]: {
    display: 'flex',
    fontSize: '18px',
    padding: '10px 5px',
    textAlign: 'center',
    maxWidth: '300px',
  },

  [`& .${classes.candorPaper}`]: {
    boxShadow: 'unset',
    borderBottom: '1px solid rgb(0 0 0 / 20%)',
    borderRadius: 'unset',
    padding: '0px 0 20px',
    width: '100%',
    height: '100%',
    maxWidth: '600px',
    '@media (max-width: 600px)': {
      padding: '1rem',
    },
  },

  [`& .${classes.candorHeading}`]: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 15,
  },

  [`& .${classes.candorSubheading}`]: {
    textAlign: 'left',
    margin: '0 0px 10px 0px',
  },
}));

const RenderBasicInputField = ({
  fieldText,
  formError,
  onChangeHandler,
  inputValue = '',
  currentFormObject,
  validateMeasurement,
}) => {
  // TODO: @iamnaj1 - Turn the below into it's own MeasurementInputField Component to clean this BasicInputField
  // NOTE: fieldText was originally thought to only be a string, seems to be an array of strings as well?
  // or potentially other values as well.
  const fieldName = typeof fieldText === 'string' ? fieldText.split('.').pop() : 'NOT_A_STRING';

  // render a number input field with the corresponding size measurement
  if (fieldName === 'weight' || fieldName === 'height') {
    const formattedValue = extractNumericCharacters(inputValue);
    const unitOfMeasurement = MEASUREMENT_CONSTRAINTS[fieldName.toUpperCase()].UNIT;

    return (
      <FormControl fullWidth sx={{ m: 2 }}>
        <InputLabel htmlFor="outlined-adornment-amount" sx={{ textTransform: 'capitalize' }} error={formError}>
          {fieldName}
        </InputLabel>
        <OutlinedInput
          onChange={(e) => {
            onChangeHandler(e.target.value);
          }}
          onBlur={(e) => {
            validateMeasurement(e.target.value);
          }}
          error={formError}
          value={formattedValue || ''}
          id="outlined-adornment-amount"
          endAdornment={<InputAdornment position="start">{unitOfMeasurement}</InputAdornment>}
          label="Amount"
        />
        {formError && (
          <FormHelperText sx={{ m: 0 }} error={formError}>
            {MEASUREMENT_ERROR[fieldName]?.range}
          </FormHelperText>
        )}
      </FormControl>
    );
  }

  return (
    <TextField
      type="text"
      error={formError}
      helperText={formError && 'Please provide a little more info'}
      variant="outlined"
      multiline
      fullWidth
      rows={currentFormObject.maxRows}
      className={classes.input}
      onChange={(e) => onChangeHandler(e.target.value)}
      value={inputValue || ''}
    />
  );
};

const BasicFormElementPresentation = (props) => {
  const {
    currentFormObject,
    handleInput,
    handleSkip,
    inputValue,
    handleChange,
    formError,
    simpleView,
    isLoading,
    setFormError,
    validateMeasurement,
  } = props;

  const { userType } = useContext(AuthContext);

  const buttonValue = getFormsButtonText({ isUpdateDetails: false, isLoading });

  // check input value if not null or empty string or undefined
  const hasTrueValue = (value) => {
    if (value === null || value === '' || value === undefined) {
      return false;
    }
    return true;
  };

  return (
    <StyledContainer maxWidth="lg">
      <Grid container spacing={0} className={isCandor(userType) ? classes.accountGridContainer : classes.gridContainer}>
        <Paper className={simpleView ? classes.candorPaper : classes.paper}>
          <form>
            <div>
              <Typography
                className={simpleView && classes.candorHeading}
                variant={simpleView ? 'body1' : 'h5'}
                align="center"
                gutterBottom
              >
                {currentFormObject.headingText}
              </Typography>
            </div>
            {currentFormObject.subheadingText && (
              <div>
                <Typography
                  className={simpleView ? classes.candorSubheading : classes.subheading}
                  variant="body2"
                  align="center"
                  gutterBottom
                >
                  {currentFormObject.subheadingText}
                </Typography>
              </div>
            )}
            <Box align={simpleView ? 'left' : 'center'}>
              {simpleView ? (
                <Typography className={classes.candorAnswer}>{inputValue || 'N/A'}</Typography>
              ) : (
                <Container className={classes.inputContainer}>
                  <RenderBasicInputField
                    fieldText={currentFormObject.fieldText}
                    formError={formError}
                    onChangeHandler={handleChange}
                    inputValue={inputValue}
                    setFormError={setFormError}
                    currentFormObject={currentFormObject}
                    validateMeasurement={validateMeasurement}
                  />
                </Container>
              )}
            </Box>
            {!isCandor(userType) && (
              <div>
                {currentFormObject.skippable && !inputValue ? (
                  <Button className={classes.button} variant="contained" color="primary" onClick={handleSkip}>
                    Skip
                  </Button>
                ) : (
                  <Button
                    disabled={!inputValue || isLoading || formError}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleInput}
                  >
                    {buttonValue}
                  </Button>
                )}
              </div>
            )}
          </form>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

RenderBasicInputField.propTypes = {
  fieldText: PropTypes.string.isRequired,
  formError: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  currentFormObject: PropTypes.shape({}).isRequired,
  validateMeasurement: PropTypes.func.isRequired,
};

BasicFormElementPresentation.propTypes = {
  currentFormObject: PropTypes.shape({}).isRequired,
  handleInput: PropTypes.func.isRequired,
  handleSkip: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  formError: PropTypes.bool.isRequired,
  simpleView: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  setFormError: PropTypes.func.isRequired,
  validateMeasurement: PropTypes.func.isRequired,
};

export default BasicFormElementPresentation;
