import React from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, TableHead } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

/**
 * Renders a table header with optional column widths and alignment.
 *
 * @component
 * @param {Object} props - Component props
 * @param {Array<{ label: string, align?: 'inherit' | 'left' | 'center' | 'right' | 'justify', colWidth?: string }>} props.headerConfig
 * An array of objects defining the table header configuration. Each object can have:
 * - `label`: The text content for the header cell.
 * - `align` (optional): Text alignment within the header cell (`inherit` by default).
 * - `colWidth` (optional): Width of the column.
 *
 * @param {Object<string, string>} [props.className={}]
 * An optional object defining custom class names for table header elements:
 * - `tableHead`: Class for the `<TableHead>` component.
 * - `tableRow`: Class for the `<TableRow>` component.
 * - `tableCell`: Class for the `<TableCell>` components.
 *
 * @returns {React.ReactElement|null} A rendered table header or `null` if the configuration is invalid.
 */

const TableHeader = ({ headerConfig, className = {} }) => {
  if (!Array.isArray(headerConfig) || !headerConfig.length) {
    return null;
  }

  const hasColGroup = headerConfig.some(({ colWidth }) => colWidth);

  return (
    <>
      {hasColGroup && (
        <colgroup>
          {headerConfig.map(({ colWidth }) => (colWidth ? <col key={uuidv4()} width={colWidth} /> : null))}
        </colgroup>
      )}
      <TableHead className={className?.tableHead || null}>
        <TableRow className={className?.tableRow || null}>
          {headerConfig.map(({ label, align }) => (
            <TableCell key={uuidv4()} align={align || 'inherit'} className={className?.tableCell || null}>
              {label || ''}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );
};

TableHeader.propTypes = {
  headerConfig: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  className: PropTypes.objectOf(PropTypes.string),
};

export default TableHeader;
