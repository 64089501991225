import {
  collection,
  doc,
  documentId,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { captureException as sentryCaputureException } from '@sentry/react';

import { db } from '../../../firebase-config';
import { tokenizeNames, tokenizeSearchInput } from './patientSearchTokenization';
import { processFieldData } from '../../../utils/formHelpers';

export const QUERY_RESULT_LIMIT = 100;

// Validate token to ensure it doesn't start or end with a dot
export const isValidToken = (token) => token && !token.startsWith('.') && !token.endsWith('.') && !token.includes('..');

// Create firestore query to search for patients by the tokenized name
export const queryPatientsByName = ({ nameInput, lastVisibleResult }) => {
  const name = nameInput.trim();
  if (!name) {
    return { docs: [] };
  }

  // Tokenize input and filter invalid names
  const tokenizedInput = tokenizeSearchInput(name).filter((token) => isValidToken(token));

  if (tokenizedInput.length === 0) {
    return { docs: [] };
  }

  const whereQueries = tokenizedInput.map((tokenizedName) =>
    where(processFieldData(`searchTokens.${tokenizedName}`), '==', true),
  );

  const queries = [
    collection(db, 'patients'),
    orderBy(documentId()),
    ...whereQueries,
    lastVisibleResult ? startAfter(lastVisibleResult) : null,
  ].filter((q) => q);

  return {
    getCount: () => getCountFromServer(query(...queries)),
    getDocs: () => getDocs(query(...queries, limit(QUERY_RESULT_LIMIT))),
  };
};

// Populate the searchTokens field in the patient document with the tokenized names
export const populatePatientSearchTokens = async (patientId) => {
  const patientDoc = (await getDoc(doc(db, 'patients', patientId))).data() || {};

  const searchTokens = tokenizeNames(patientDoc.firstName, patientDoc.lastName, patientDoc.legalFirstName, patientDoc.legalLastName).reduce(
    (acc, tokenizedName) => ({ ...acc, [tokenizedName]: true }),
    {},
  );

  try {
    await updateDoc(doc(db, 'patients', patientId), { searchTokens });
  } catch (error) {
    sentryCaputureException(error, {
      extra: {
        patientId,
        searchTokens,
        issueIn: 'populatePatientSearchTokens',
        message: 'Cannot update patient search tokens',
      },
    });
  }
};

// Populate the searchTokens field in the patients collection with the tokenized names
export const batchPopulatePatientsSearchTokens = async () => {
  const patients = await getDocs(query(collection(db, 'patients')));
  const batch = writeBatch(db);

  patients.forEach((patient) => {
    const searchTokens = tokenizeNames(patient.data().firstName, patient.data().lastName).reduce(
      (acc, tokenizedName) => ({ ...acc, [tokenizedName]: true }),
      {},
    );
    batch.update(patient.ref, { searchTokens });
  });

  await batch.commit();
};
